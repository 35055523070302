<template>
  <div id="home">
    <!-- <div v-if="!loadkont" class="preloader">
      <div class="icon"></div>
    </div> -->
    <!-- Start Main Slider-->
    <section
      class="main-slider style1 home2"
    >
      <div class="parallax-scene parallax-scene-1">
        <span
          data-depth="0.20"
          class="parallax-layer shape wow zoomInLeft"
          data-wow-duration="1000ms"
        ></span>
        <span
          data-depth="0.10"
          class="parallax-layer shape2 wow zoomInRight"
          data-wow-duration="1000ms"
        ></span>
      </div>
      <div class="slider-box">
        <!-- Banner Carousel -->
        <div class="banner-carousel owl-theme owl-carousel">
          <!-- Slide -->
          <div class="slide"
          v-for="(slide, index ) in Slides.Dosyalar" 
          :key="index"
          :class="[Slides,{ 'active' : index === 0 }]"
          >
            <div class="auto-container">
              <div class="content">
                <h2>
                  {{Belediyebilgiler.belediyeadi}} <br> Belediyesi
                </h2>
              </div>
            </div>
            <div class="image-holder">
              <img
                :src="slide.dosyaurl"
                alt="Slide Image"
              />
            </div>
          </div>
          <!-- Slide -->
          <div class="slide">
            <div class="auto-container">
              <div class="content">
                <h2>
                  {{Belediyebilgiler.belediyeadi}} <br> Belediyesi
                </h2>
              </div>
            </div>
            <div class="image-holder">
              <img
               :src="Belediyebilgiler.anaresim"
                alt="Slide Image"
              />
            </div>
          </div>
          <!-- Slide -->
          <div class="slide">
            <div class="auto-container">
              <div class="content">
                <h2>
                   {{Belediyebilgiler.belediyeadi}} <br> Belediyesi
                </h2>
              </div>
            </div>
            <div class="image-holder">
              <img
                :src="Belediyebilgiler.anaresim"
                alt="Slide Image"
              />
            </div>
          </div>
          <!-- Slide -->
          <div class="slide">
            <div class="auto-container">
              <div class="content">
                <h2>
                 {{Belediyebilgiler.belediyeadi}} <br> Belediyesi
                </h2>
              </div>
            </div>
            <div class="image-holder">
              <img
                :src="Belediyebilgiler.anaresim"
                alt="Slide Image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Main Slider -->



    <!--Start Blog Style1 Area-->
    <section class="blog-style1-area">
      <div class="container">
        <div class="sec-title style2 text-center">
          <h3 class="thm-clr2">Haberler</h3>
          <h2>Güncel Haberler</h2>
        </div>
        <div class="row">

          <!--Start Single blog Style1-->
          <div v-for="(haber, index) in Haberler.slice(0,3)" :key="index" class="col-xl-4 col-lg-4">
            <div class="single-blog-style1">
              <div class="img-holder">
                <div class="inner">
                  <img
                    :src="haber.kapak"
                    :style="{
                      width: '100%',
                      height: '300px'
                    }"
                    alt="Awesome Image"
                  />
                </div>
                <div class="date-box">
                  <h2 class="thm-clr2">{{haber.haberTarihi.split(" ")[0]}}<br /><span>{{haber.haberTarihi.split(" ")[1].replace(",", " ")}}</span></h2>
                </div>
              </div>
              <div class="text-holder">
                <h3>
                  <a @click="detayagit(haber.haberId)">{{haber.haberAdi}}</a>
                </h3>
                <div class="text">
                  <p v-html="haber.haberAciklamasi.slice(0,55)"></p>
                </div>
                <div class="button">
                  <a @click="detayagit(haber.haberId)">Habere Git</a>
                </div>
              </div>
            </div>
          </div>
          <!--End Single blog Style1-->
        </div>
      </div>
    </section>
    <!--End Blog Style1 Area-->

    <!--Start About Style2 Area-->
    <section class="about-style2-area mt-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-5">
            <div class="about-style2-left-box">
              <div class="about-style2-image-box">
                <img
                  :src="BaskanBilgisi.kapak"
                  alt="Awesome Image"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-7">
            <div class="about-style2-text-box">
              <div class="sec-title">
                <div class="big-title">Başkan</div>
                <h3 class="thm-clr2">Başkan</h3>
                <h2>
                  Başkan Özgeçmişi
                </h2>
              </div>
              <div class="inner-contant">
                <div class="text">
                  <p v-html="BaskanBilgisi.ozgecmis"></p>
                </div>
                <div class="button">
                  <router-link class="btn-one" to="/ozgecmis">
                    <span class="txt">Devamına Git</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End About Style2 Area-->

    
        <!--Start Features Style2 Area-->
        <section class="features-style2-area">
            <div class="container">
                <div class="sec-title text-center">
                    <div class="big-title">Projeler</div>
                    <h3 class="thm-clr2">Projeler</h3>
                    <h2>Belediyemizin Projeleri</h2>
                </div>
                <div class="row">
                    <!--Start Single Feature Box-->
                    <div class="col-xl-4 col-lg-4 col-md-12"
                    v-for="(proje,index) in Projeler.slice(0,3)"
                    :key="index"
                    >
                        <div class="single-feature-box">
                            <div class="img-holder">
                                <div class="inner">
                                    <img class="js-tilt" :src="proje.kapak" alt="Awesome Image"
                                    :style="{
                                      width: '100%',
                                      height: '400px'
                                    }"
                                    >
                                </div>
                                <div class="title text-center">
                                    <h3>{{ proje.projeTarihi }}<br> {{proje.projeAdi.slice(0,40)}}...</h3>
                                    <a @click="projedetayagit(proje.projeId)">Projeye Git</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End Single Feature Box-->
                </div>
            </div>
        </section>
        <!--End Features Style2 Area-->

    <!--Start Testimonial style2 Area-->
    <section class="testimonial-style2-area">
      <div
        class="layer-outer"
        style="
          background-image: url(assets/images/shape/testimonial-style2-shape-bg.jpg);
        "
      >
        <div
          class="left"
               :style="{
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'auto 100%',
                  backgroundPosition: 'top center',
                  backgroundImage:`url(${BaskanBilgisi.kapak})`
                  }"
        ></div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xl-6"></div>
          <div class="col-xl-6">
            <div class="testimonial-outer-box style2">
              <div class="sec-title">
                <div class="big-title">Başkan Mesajı</div>
                <h3 class="thm-clr2">{{BaskanBilgisi.baskanadi}}</h3>
                <h2>Başkan Mesajı</h2>
              </div>
              <div class="testimonial-carousel owl-carousel owl-theme">
                <!--Start Single Testimonial Style1-->
                <div class="single-testimonial-style1 style2">
                  <div class="text-box">
                    <p v-html="BaskanBilgisi.baskanslogan">
                    </p>
                  </div>
                  <div class="clinet-info">
                    <div class="img-box">
                      <img
                         :src="BaskanBilgisi.kapak"
                        alt="Awesome Image"
                      />
                    </div>
                    <div class="name-box">
                      <h3>{{BaskanBilgisi.baskanadi}}</h3>
                    </div>
                  </div>
                </div>
                <!--End Single Testimonial Style1-->
                <!--Start Single Testimonial Style1-->
                <div class="single-testimonial-style1 style2">
                  <div class="text-box">
                    <p v-html="BaskanBilgisi.mesaj">
                    </p>
                  </div>
                  <div class="clinet-info">
                    <div class="img-box">
                      <img
                         :src="BaskanBilgisi.kapak"
                        alt="Awesome Image"
                      />
                    </div>
                    <div class="name-box">
                      <h3>{{BaskanBilgisi.baskanadi}}</h3>
                    </div>
                  </div>
                </div>
                <!--End Single Testimonial Style1-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End Testimonial Style2 Area-->

    <!--Start coming soon Style2 area-->
    <section class="coming-soon-style2-area mt-5">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div
              class="coming-soon-content-style2"
              style="
                background-image: url(assets/images/shape/coming-soon-content-style2-bg.jpg);
              "
            >
              <div class="title-box">
                <h2>
                  Mobil uygulamamızı <br />
                  indirdiniz mi ?
                </h2>
              </div>
              <div class="timer-box">
                <div class="countdown-timer">
                  <div class="default-coundown">
                    <div class="box">
                      <ul >
                        <li><a href="#"><i class="fab fa-google-play"></i></a></li>
                        <li><a href="#"><i class="fab fa-app-store"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--End coming soon Style2 area -->



        <!--Start Terget Area-->
    <section class="terget-area">
      <div class="container">
        <div class="sec-title style2 text-center">
          <h3 class="thm-clr2">İnteraktif Hizmetler</h3>
        </div>
        <div class="row">
          <!--Start Single Terget Box-->
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="single-terget-box text-center">
              <div class="icon">
                <span class="flaticon-save-water thm-clr2"></span>
                <div class="static-shape">
                  <img src="assets/images/shape/shape-2.png" alt="Shape" />
                </div>
                <div class="overlay-shape">
                  <img src="assets/images/shape/shape-3.png" alt="Shape" />
                </div>
              </div>
              <div class="text">
                <h3>
                  <router-link to="/mesaj">
                    Başkan Mesaj
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
          <!--End Single Terget Box-->
          <!--Start Single Terget Box-->
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="single-terget-box text-center">
              <div class="icon">
                <span class="flaticon-open-book thm-clr2"></span>
                <div class="static-shape">
                  <img src="assets/images/shape/shape-2.png" alt="Shape" />
                </div>
                <div class="overlay-shape">
                  <img src="assets/images/shape/shape-3.png" alt="Shape" />
                </div>
              </div>
              <div class="text">
                <h3>
                  <router-link to="/kentrehberi">
                    Kent Rehberi
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
          <!--End Single Terget Box-->
          <!--Start Single Terget Box-->
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="single-terget-box text-center">
              <div class="icon">
                <span class="flaticon-personal-security thm-clr2"></span>
                <div class="static-shape">
                  <img src="assets/images/shape/shape-2.png" alt="Shape" />
                </div>
                <div class="overlay-shape">
                  <img src="assets/images/shape/shape-3.png" alt="Shape" />
                </div>
              </div>
              <div class="text">
                <h3>
                  <router-link to="/kararlar">
                  Kararlar
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
          <!--End Single Terget Box-->

          <!--Start Single Terget Box-->
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="single-terget-box text-center">
              <div class="icon">
                <span class="flaticon-campaign thm-clr2"></span>
                <div class="static-shape">
                  <img src="assets/images/shape/shape-2.png" alt="Shape" />
                </div>
                <div class="overlay-shape">
                  <img src="assets/images/shape/shape-3.png" alt="Shape" />
                </div>
              </div>
              <div class="text">
                <h3>
                  <router-link to="/duyuruIlanlar">
                    Duyurular
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
          <!--End Single Terget Box-->
          <!--Start Single Terget Box-->
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="single-terget-box text-center">
              <div class="icon">
                <span class="flaticon-buildings thm-clr2"></span>
                <div class="static-shape">
                  <img src="assets/images/shape/shape-2.png" alt="Shape" />
                </div>
                <div class="overlay-shape">
                  <img src="assets/images/shape/shape-3.png" alt="Shape" />
                </div>
              </div>
              <div class="text">
                <h3>
                  <router-link to="/vefat">
                    Vefat Edenler
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
          <!--End Single Terget Box-->
          <!--Start Single Terget Box-->
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="single-terget-box text-center">
              <div class="icon">
                <span class="flaticon-economic thm-clr2"></span>
                <div class="static-shape">
                  <img src="assets/images/shape/shape-2.png" alt="Shape" />
                </div>
                <div class="overlay-shape">
                  <img src="assets/images/shape/shape-3.png" alt="Shape" />
                </div>
              </div>
              <div class="text">
                <h3>
                  <router-link to="/tarihce">
                    Etik Komisyonu
                  </router-link>
                </h3>
              </div>
            </div>
          </div>
          <!--End Single Terget Box-->
        </div>
      </div>
    </section>
    <!--End Terget Area-->

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      urll: "",
      Haberler: [],
      Slides: {},
      Ilanlar: [],
      Kararlar: [],
      Yerler: [],
      Baskanbilgisi: {},
      BaskanBilgisi:{},
      Projeler: [],
      loadkont: false,
      kont: false,
      domAD: "",
      Belediyebilgiler: {},
    };
  },
  components: {},
  // linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9)),
  //background-repeat: round; background-attachment: fixed;
  async created() {
    const domainAd = window.location.hostname;
    this.domAD = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.belediyecek();
    await this.haberlercek();
    await this.$store.dispatch("vericekme", "Genel");
    await this.$store.dispatch("vericekme", "Slides");
    this.Slides = this.$store.getters.getSlides;
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    this.urll = `url(${this.Belediyebilgiler.kapak})`;

    this.loadkont = true;
    await this.baskancek();
    await this.duyuruilancek();
    await this.kararlarcek();
    await this.yerlercek();
    await this.projelercek();

    this.scriptekleslide();
  },
  methods: {
    detayagit(ID) {
      this.$router.push("/haberdetay/" + ID);
    },
    projedetayagit(ID) {
      this.$router.push("/projedetay/" + ID);
    },
    yerdetayagit(ID) {
      this.$router.push("/kentrehberidetay/" + ID);
    },
    duyurudetayagit(ID) {
      this.$router.push("/duyurudetay/" + ID);
    },
    async haberlercek() {
      await this.$store.dispatch("vericekme", "Haberler");
      const habermap = this.$store.getters.getHaberler;
      this.Haberler = Object.values(habermap);
      this.Haberler.sort(function (b, a) {
        return a.haberId - b.haberId;
      });
      this.kont = true;
    },
    
    async belediyecek() {},
    async scriptekleslide() {

      // // SCRİPT
      // let customscript3 = await document.createElement("script");
      // customscript3.setAttribute("src", "js/plugins.js");
      // document.head.appendChild(customscript3);
      // // SCRİPT
      // let customscript4 = await document.createElement("script");
      // customscript4.setAttribute("src", "js/jquery-2.1.1.min.js");
      // document.head.appendChild(customscript4);
    },
    async baskancek() {
      await this.$store.dispatch("vericekme", "Baskan");
      this.Baskanbilgisi = this.$store.getters.getBaskan;
      this.BaskanBilgisi = this.$store.getters.getBaskan;
    },
     async duyuruilancek() {
      await this.$store.dispatch("vericekme", "DuyuruIlanlar");
      const dilanlar = this.$store.getters.getDuyuruIlanlar;
      this.Ilanlar = Object.values(dilanlar);
      this.Ilanlar.sort(function (b, a) {
        return a.duyruilanId - b.duyruilanId;
      });
    },
    async kararlarcek() {
      await this.$store.dispatch("vericekme", "Kararlar");
      const kararlar1 = this.$store.getters.getKararlar;
      this.Kararlar = Object.values(kararlar1);
      this.Kararlar.sort(function (b, a) {
        return a.kararId - b.kararId;
      });
    },
    async yerlercek() {
      await this.$store.dispatch("vericekme", "Yerler");
      const yerler1 = this.$store.getters.getYerler;
      this.Yerler = Object.values(yerler1);
      this.Yerler.sort(function (b, a) {
        return a.yerId - b.yerId;
      });
    },
    async projelercek() {
      await this.$store.dispatch("vericekme", "Projeler");
      const projeler1 = this.$store.getters.getProjeler;
      this.Projeler = Object.values(projeler1);
      this.Projeler.forEach((el) => {
        el.projeDurum = this.projectStatus(el.projeDurum);
      });
      this.Projeler.sort(function (b, a) {
        return a.projeId - b.projeId;
      });
      console.log(this.Projeler);
    },
    projectStatus(status) {
      switch (status) {
        case 1:
          console.log("geldi");
          return "Planlanan";
        case 2:
          console.log("geldi");
          return "Devam Ediyor";
        case 3:
          console.log("geldi");
          return "Tamamlandı";
        default:
          break;
      }
    },
  },
  computed: {
    teststyle() {
      return {
        background:
          "linear-gradient(0deg, rgba(255, 255, 255, 0.9),rgba(255, 255, 255, 0.9))," +
          this.urll,
        "background-repeat": "round",
        "background-attachment": "fixed",
      };
    },
  },
  mounted() {
      // let customscript1 = document.createElement("script");
      // customscript1.setAttribute("src", "/assets/js/owl.js");
      // document.head.appendChild(customscript1);
      // let customscript6 = document.createElement("script");
      // customscript6.setAttribute("src", "/assets/js/jquery.js");
      // document.head.appendChild(customscript6);
      let customscript5 = document.createElement("script");
      customscript5.setAttribute("src", "/assets/js/owl.js");
      document.head.appendChild(customscript5);
      // let customscript7 = document.createElement("script");
      // customscript7.setAttribute("src", "/assets/js/slick.js");
      // document.head.appendChild(customscript7);
      let customscript4 = document.createElement("script");
      customscript4.setAttribute("src", "/assets/js/jQuery.style.switcher.min.js");
      document.head.appendChild(customscript4);
      let customscript3 = document.createElement("script");
      customscript3.setAttribute("src", "/assets/js/custom.js");
      document.head.appendChild(customscript3);
    // let twitscrpt1 = document.createElement("script");
    //   twitscrpt1.setAttribute("src", "/assets/js/jquery.countdown.min.js");
    //   document.head.appendChild(twitscrpt1);
  },
};
</script>
<style>
#videoContainment {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  left: 0;
  top: 0;
  overflow: hidden;
}
.default-coundown ul li i {
  font-size: 60px; 
  padding-top: 8%;
  color: #bebebe;
}
.default-coundown ul li i:hover {
  color: #fff
}
</style>
