export const genelmutation = (state, databilgisi) => {
  switch (databilgisi.col) {
    case "Kararlar":
      state.Kararlar = databilgisi.data;
      state.kararlarkontrol = true;
      break;
    case "Cralanlar":
      state.Cralanlar = databilgisi.data;
      state.cralanlarkontrol = true;
      break;
    case "MeclisGundemler":
      state.MeclisGundemler = databilgisi.data;
      state.meclisgundemlerkontrol = true;
      break;
    case "Muhtarlar":
      state.Muhtarlar = databilgisi.data;
      state.muhtarlarkontrol = true;
      break;
    case "EskiBaskanlar":
      state.EskiBaskanlar = databilgisi.data;
      state.eskibaskanlarkontrol = true;
      break;
    case "Encumenlar":
      state.Encumenlar = databilgisi.data;
      state.encumenlarkontrol = true;
      break;
    case "Uyeler":
      state.Uyeler = databilgisi.data;
      state.uyelerkontrol = true;
      break;
    case "Birimler":
      state.Birimler = databilgisi.data;
      state.birimlerkontrol = true;
      break;
    case "Raporlar":
      state.Raporlar = databilgisi.data;
      state.raporlarkontrol = true;
      break;
    case "Baskan":
      state.BaskanBilgileri = databilgisi.data;
      state.baskankontrol = true;
      break;
    case "Genel":
      state.BelediyeGenelBilgiler = databilgisi.data;
      state.genelkontrol = true;
      break;
    case "Projeler":
      state.Projeler = databilgisi.data;
      state.projekontrol = true;
      break;
    case "Haberler":
      state.Haberler = databilgisi.data;
      state.haberkontrol = true;
      break;
    case "Ilce":
      state.IlceBilgiler = databilgisi.data;
      state.ilcekontrol = true;
      break;
    case "Slides":
      state.Slides = databilgisi.data;
      state.slideskontrol = true;
      break;
    case "Sehitler":
      state.Sehitler = databilgisi.data;
      state.sehitkontrol = true;
      break;
    case "Yerler":
      state.Yerler = databilgisi.data;
      state.yerkontrol = true;
      break;
    case "Vefatlar":
      state.Vefatlar = databilgisi.data;
      state.vefatkontrol = true;
      break;
    case "Evlenenler":
      state.Evlenenler = databilgisi.data;
      state.evlenenkontrol = true;
      break;
    case "Belgeler":
      state.Belgeler = databilgisi.data;
      state.belgekontrol = true;
      break;
    case "DuyuruIlanlar":
      state.DuyuruIlanlar = databilgisi.data;
      state.duyuruilankontrol = true;
      break;
    default:
      break;
  }
};
export const domainmut = (state, domainId) => {
  state.domainId = domainId;
};
