<template>
  <div>
    <footer class="footer-area">
      <div class="footer">
        <div class="container">
          <div class="row">
            <!--Start single footer widget-->
            <div
              class="
                col-xl-6 col-lg-5 col-md-12 col-sm-12
                wow
                animated
                fadeInUp
              "
              data-wow-delay="0.1s"
            >
              <div class="single-footer-widget marbtm">
                <div class="our-company-info">
                  <div class="footer-logo">
                    <a href="/"
                      ><img
                        :src="Belediyebilgiler.kapak"
                        :style="{
                          width: '100%',
                          height: '200px'
                        }"
                        alt="Logo"
                        title="Logo"
                    /></a>
                  </div>
                  <div class="footer-social-links">
                    <ul>
                      <li>
                        <a class="fb" :href="Belediyebilgiler.face"
                          ><i class="fa fa-facebook" aria-hidden="true"></i
                        ></a>
                      </li>
                      <li>
                        <a class="tw" :href="Belediyebilgiler.twit"
                          ><i class="fa fa-twitter" aria-hidden="true"></i
                        ></a>
                      </li>
                      <li>
                        <a class="linked" :href="Belediyebilgiler.link"
                          ><i class="fa fa-linkedin" aria-hidden="true"></i
                        ></a>
                      </li>
                      <li>
                        <a class="gplus" :href="Belediyebilgiler.google"
                          ><i class="fa fa-google-plus" aria-hidden="true"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <!--End single footer widget-->
            <!--Start single footer widget-->
            <div
              class="col-xl-3 col-lg-3 col-md-6 col-sm-12 wow animated fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="single-footer-widget marginleft">
                <div class="title">
                  <h3>Menü</h3>
                </div>
                <ul class="site-links">
                  <li>
                    <router-link to="/kentrehberi">Kent Rehberi</router-link>
                  </li>
                  <li>
                    <router-link to="/vefat">Vefat Edenler</router-link>
                  </li>
                  <li>
                    <router-link to="/galeri">Galeri</router-link>
                  </li>
                  <li>
                    <router-link to="/birimler">Birimler</router-link>
                  </li>
                  <li>
                    <router-link to="/duyuruilanlar">Duyurular</router-link>
                  </li>
                </ul>
              </div>
            </div>
            <!--End single footer widget-->
            <!--Start single footer widget-->
            <div
              class="col-xl-3 col-lg-4 col-md-6 col-sm-12 wow animated fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="single-footer-widget pdtop marginleft">
                <div class="title">
                  <h3>İletişim Bilgileri</h3>
                </div>
                <div class="footer-contact-info">
                  <p>
                    {{Belediyebilgiler.belediyeadresi}}
                  </p>
                  <ul>
                    <li>
                      <div class="left">
                        <p>Telefonn:</p>
                      </div>
                      <div class="right">
                        <a href="tel:123456789">{{ Belediyebilgiler.iletisim }}</a>
                      </div>
                    </li>
                    <li>
                      <div class="left">
                        <p>Email:</p>
                      </div>
                      <div class="right">
                        <a href="mailto:info@templatepath.com">{{ Belediyebilgiler.mail}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--End single footer widget-->
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="outer-box">
            <div class="copyright-text">
              <p><a href="#">{{Belediyebilgiler.belediyeadi}} Belediyesi</a> &copy; 2021 Tüm Hakları Saklıdır</p>
            </div>
            <div class="footer-menu">
              <ul>
                <li><a href="#">Kullanım Şartları</a></li>
                <li><a href="#">Gizlilik Politikası</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>