<template>
  <div>
    <header class="main-header header-style-two">
      <!--Start Header Top-->
      <div class="header-top" style="border-bottom: 1px solid #e0e5ec">
        <div class="outer-container">
          <div class="outer-box clearfix">
            <div class="header-top-left pull-left">
              <p>
                <i class="fa fa-bullhorn thm-clr" aria-hidden="true"></i
                >Haber,Duyurular yada Başkanımız Hakkında her şey!
              </p>
            </div>
            <div class="header-top-right clearfix pull-right">
              <div class="header-social-link">
                <ul>
                  <li>
                    <a :href="Belediyebilgiler.face"
                      ><i class="fa fa-facebook" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a :href="Belediyebilgiler.twit"
                      ><i class="fa fa-twitter" aria-hidden="true"></i
                    ></a>
                  </li>
                  <li>
                    <a :href="Belediyebilgiler.insta"
                      ><i class="fa fa-instagram" aria-hidden="true"></i
                    ></a>
                  </li>
                </ul>
              </div>
              <div class="header-contact-info">
                <ul>
                  <li>
                    <span class="flaticon-phone thm-clr"></span
                    ><a href="tel:123456789">{{Belediyebilgiler.iletisim}}</a>
                  </li>
                  <li>
                    <span class="flaticon-mail thm-clr"></span
                    ><a href="mailto:info@templatepath.com">{{Belediyebilgiler.mail}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End Header Top-->
      <!--Start header-->
      <div class="header-style2">
        <div class="outer-container">
          <div class="outer-box clearfix">
            <!--Start Header Left-->
            <div class="header-left-style2 pull-left">
              <div class="logo">
                <router-link to="/"
                  ><img
                    :src="Belediyebilgiler.kapak"
                    :style="{
                      width: '100%',
                      height: '70px',
                      objectfit: 'contain'
                    }"
                    alt="Logo"
                    title=""
                /></router-link>
              </div>
              <div class="nav-outer">
                <!--Mobile Navigation Toggler-->
                <div class="mobile-nav-toggler">
                  <div class="inner">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </div>
                </div>
                <!-- Main Menu -->
                <nav class="main-menu style2 navbar-expand-md navbar-light">
                  <div
                    class="collapse navbar-collapse show clearfix"
                    id="navbarSupportedContent"
                  >
                    <ul class="navigation clearfix">
                      <li class="current">
                        <router-link to="/">Anasayfa</router-link>
                      </li>
                      <li class="dropdown">
                        <router-link to="#">Başkan</router-link>
                        <ul>
                          <li><router-link to="/ozgecmis">Başkanın Özgeçmişi</router-link></li>
                          <li><router-link to="/mesaj">Başkandan Mesaj</router-link></li>
                          <li><router-link to="/eskibaskanlar">Eski Başkanlar</router-link></li>
                          <li>
                            <router-link to="/baskangaleri"
                              >Başkanın Fotoğrafları</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">Kurumsal</a>
                        <ul class="megamenu">
                           <li><router-link class="dropdown-item" to="/birimler"> Birimler</router-link></li>
                          <li><router-link class="dropdown-item" to="/meclisuyeleri">Meclis Üyeleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/encumenlarimiz">Encümenlerimiz</router-link></li>
                          <li><router-link class="dropdown-item" to="/kararlar">Meclis Kararları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Faaliyet Raporları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Meclis Gündemleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/tarihce">Etik Komisyonu</router-link></li>
                          <li><router-link class="dropdown-item" to="/kvkk">KVKK Aydınlatma Metni</router-link></li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">Hizmetler</a>
                        <ul class="megamenu">
                        <li><a class="dropdown-item" :href="Belediyebilgiler.ebelediye">E-Belediye</a></li>
                        <li><a class="dropdown-item" :href="Belediyebilgiler.nobetciecz">Nöbetçi Eczaneler</a></li>
                        <li><router-link class="dropdown-item" to="/belgeler">Belge Örnekleri</router-link></li>
                        <li><a class="dropdown-item" href="http://web.karaman.bel.tr:571/Mebis/VefatListesi.aspx">Vefat Edenler</a></li>
                        <li><router-link class="dropdown-item" to="/evlenenler">Nikah Duyuruları</router-link></li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">Güncel</a>
                        <ul>
                        <li><router-link class="dropdown-item" to="/haberler"> Haberler </router-link></li>
                        <li><router-link class="dropdown-item" to="/projeler"> Projeler </router-link></li>
                        <li><router-link class="dropdown-item" to="/duyuruilanlar">Duyurular</router-link></li>
                        </ul>
                      </li>
                      <li>
                        <router-link id="menuContact" to="/iletisim">
                          İletişim
                        </router-link>
                      </li>
                    </ul>
                  </div>
                </nav>
                <!-- Main Menu End-->
              </div>
            </div>
            <!--End Header Left-->
            <!--Start Header Right-->
            <div class="header-right-style2 pull-right clearfix">
              <div class="button">
                <a href="#">Whatsapp Destek</a>
              </div>
            </div>
            <!--End Header Right-->
          </div>
        </div>
      </div>
      <!--End header -->
      <!--Sticky Header-->
      <div class="sticky-header">
        <div class="container">
          <div class="clearfix">
            <!--Logo-->
            <div class="logo float-left">
              <a href="/" class="img-responsive">
                <img :src="Belediyebilgiler.kapak" alt="" title=""
                :style="{
                  width: '100%',
                  height: '50px',
                }"
                >
                </a>
            </div>
            <!--Right Col-->
            <div class="right-col float-right">
              <!-- Main Menu -->
              <nav class="main-menu clearfix">
               <ul class="navigation clearfix">
                      <li class="current">
                        <router-link to="/">Anasayfa</router-link>
                      </li>
                      <li class="dropdown">
                        <router-link to="#">Başkan</router-link>
                        <ul>
                          <li><router-link to="/ozgecmis">Başkanın Özgeçmişi</router-link></li>
                          <li><router-link to="/mesaj">Başkandan Mesaj</router-link></li>
                          <li><router-link to="/eskibaskanlar">Eski Başkanlar</router-link></li>
                          <li>
                            <router-link to="/baskangaleri"
                              >Başkanın Fotoğrafları</router-link
                            >
                          </li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">Kurumsal</a>
                        <ul class="megamenu">
                           <li><router-link class="dropdown-item" to="/birimler"> Birimler</router-link></li>
                          <li><router-link class="dropdown-item" to="/meclisuyeleri">Meclis Üyeleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/encumenlarimiz">Encümenlerimiz</router-link></li>
                          <li><router-link class="dropdown-item" to="/kararlar">Meclis Kararları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Faaliyet Raporları</router-link></li>
                          <li><router-link class="dropdown-item" to="/raporlar">Meclis Gündemleri</router-link></li>
                          <li><router-link class="dropdown-item" to="/tarihce">Etik Komisyonu</router-link></li>
                          <li><router-link class="dropdown-item" to="/kvkk">KVKK Aydınlatma Metni</router-link></li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">Hizmetler</a>
                        <ul class="megamenu">
                        <li><a class="dropdown-item" :href="Belediyebilgiler.ebelediye">E-Belediye</a></li>
                        <li><a class="dropdown-item" :href="Belediyebilgiler.nobetciecz">Nöbetçi Eczaneler</a></li>
                        <li><router-link class="dropdown-item" to="/belgeler">Belge Örnekleri</router-link></li>
                        <li><a class="dropdown-item" href="http://web.karaman.bel.tr:571/Mebis/VefatListesi.aspx">Vefat Edenler</a></li>
                        <li><router-link class="dropdown-item" to="/evlenenler">Nikah Duyuruları</router-link></li>
                        </ul>
                      </li>
                      <li class="dropdown">
                        <a href="#">Güncel</a>
                        <ul>
                        <li><router-link class="dropdown-item" to="/haberler"> Haberler </router-link></li>
                        <li><router-link class="dropdown-item" to="/projeler"> Projeler </router-link></li>
                        <li><router-link class="dropdown-item" to="/duyuruilanlar">Duyurular</router-link></li>
                        </ul>
                      </li>
                      <li>
                        <router-link id="menuContact" to="/iletisim">
                          İletişim
                        </router-link>
                      </li>
                    </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!--End Sticky Header-->
      <!-- Mobile Menu  -->
      <div class="mobile-menu">
        <div class="menu-backdrop"></div>
        <div class="close-btn">
          <span class="icon flaticon-multiply"></span>
        </div>

        <nav class="menu-box">
          <div class="nav-logo">
            <a href="/"
              ><img :src="Belediyebilgiler.kapak" alt="" title=""
            /></a>
          </div>
          <div class="menu-outer">
            <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
          </div>
          <!--Social Links-->
          <div class="social-links">
            <ul class="clearfix">
              <li>
                <a href="#"><span class="fab fa fa-facebook-square"></span></a>
              </li>
              <li>
                <a href="#"><span class="fab fa fa-twitter-square"></span></a>
              </li>
              <li>
                <a href="#"><span class="fab fa fa-pinterest-square"></span></a>
              </li>
              <li>
                <a href="#"
                  ><span class="fab fa fa-google-plus-square"></span
                ></a>
              </li>
              <li>
                <a href="#"><span class="fab fa fa-youtube-square"></span></a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <!-- End Mobile Menu -->
    </header>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      Belediyebilgiler: {},
      domAd: "",
    };
  },
  async created() {
    const domainAd = window.location.hostname;
    this.domAd = domainAd;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
  },
};
</script>
<style >
#menuContact:hover {
  color: #337ce8;
}
</style>